/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { DataGrid, GridRowParams, GridColumns } from '@material-ui/data-grid';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { GoPencil } from 'react-icons/go';
import api from '~/services/api';
import useAuth from '~/hooks/useAuth';
import { Container, ButtonRow, CutomizedTooltip, ButtonForm } from './styles';
import InputSelect from '~/components/Inputs/InputSelect';
import {
  ISelect,
  IAcaoEmail,
  IFormVincularEmail,
  IFormVincular,
  IEmail,
  ILoja,
  IVinculo,
} from './types';
import Separator from '~/components/Separator';
import { ConfirmButton } from '~/components/Buttons';
import Tooltip from '@atlaskit/tooltip';
import { Col, Row } from 'react-bootstrap';
import { MdDeleteForever } from 'react-icons/md';
import FooterDefault from '~/components/FooterDefault';
import DefaultLoader from '~/components/DefaultLoader';

const VincularEmail: React.FC = () => {
  const [initInicializado, setInitInicializado] = useState(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [isDisableField, setIsDisabledField] = useState<boolean>(false);
  const [isChanged, setChanged] = useState<boolean>(false);
  const [acoes, setAcoes] = useState<ISelect[]>([]);
  const [emails, setEmails] = useState<ISelect[]>([]);
  const [lojas, setLojas] = useState<ISelect[]>([]);
  const [lojasAtivas, setLojasAtivas] = useState<ISelect[]>([]);
  const [vinculos, setVinculos] = useState<IVinculo[]>([]);
  const user = useAuth();

  const [formVincularEmail, setFormVincularEmail] =
    useState<IFormVincularEmail>({
      cod_acao: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      Vinculos: {
        vinculos: [],
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
    });

  const [formVincular, setFormVincular] = useState<IFormVincular>({
    cod_email: {
      value: { label: '', value: undefined },
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    cod_loja: {
      value: { label: '', value: undefined },
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
  });

  const MySwal = withReactContent(Swal);

  const unidadeColumns: GridColumns = [
    {
      field: 'cod_loja',
      headerName: 'Loja',
      width: 450,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const showLojas = lojas.find((loja) => {
          return loja.value === row.cod_loja;
        })?.label;
        return (
          <Tooltip
            component={CutomizedTooltip}
            position="top"
            content={showLojas}
          >
            <div
              className="text-truncate"
              style={{
                position: 'relative',
                width: '430px',
              }}
            >
              {showLojas}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'cod_email',
      headerName: 'E-mail',
      width: 500,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const showEmail = emails.find((email) => {
          return email.value === row.cod_email;
        })?.label;
        return (
          <Tooltip
            component={CutomizedTooltip}
            position="top"
            content={showEmail}
          >
            <div
              className="text-truncate"
              style={{
                position: 'relative',
                width: '500px',
              }}
            >
              {showEmail}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      headerAlign: 'center',
      sortable: false,
      width: 230,
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const onEditReg = () => {
          setIsUpdate(true);
          setIsDisabledField(true);
          const reg = formVincularEmail.Vinculos.vinculos.find((item) => {
            return Number(item.cod_loja.value.value) === Number(row.cod_loja);
          });
          if (reg) {
            setFormVincular(reg);
          }
        };
        const onDeleteReg = async () => {
          MySwal.fire({
            title: `Vincular e-mail`,
            text: 'Esta ação irá remover a configuração atual. Deseja continuar?',
            showCancelButton: true,
            confirmButtonColor: '#07289e',
            cancelButtonColor: '#ff7b7b',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                const res = await api.delete(
                  `/vincularemail/${row.cod_acao}/${row.cod_loja}`,
                );
                const { success, message } = res.data;
                if (!success) {
                  throw new Error(message);
                }
                toast.success(message);
                onClearForm();
              } catch (error: any) {
                toast.warning(error.message);
              }
              const filtered = formVincularEmail.Vinculos.vinculos.filter(
                (item) => {
                  return (
                    Number(item.cod_loja.value.value) !== Number(row.cod_loja)
                  );
                },
              );
              setFormVincularEmail({
                ...formVincularEmail,
                Vinculos: {
                  ...formVincularEmail.Vinculos,
                  vinculos: filtered,
                },
              });
            }
          });
        };
        return (
          <>
            <Container style={{ margin: '0px', padding: '0px' }}>
              <Row style={{ justifyContent: 'center' }}>
                <Col>
                  <ButtonRow
                    type="button"
                    onClick={onEditReg}
                    style={{ marginRight: '0px' }}
                  >
                    <GoPencil size={20} style={{ color: '#72AB90' }} /> Editar
                  </ButtonRow>
                </Col>
                <Col>
                  <ButtonRow type="button" onClick={onDeleteReg}>
                    <MdDeleteForever size={20} style={{ color: '#E63C3C' }} />{' '}
                    Excluir
                  </ButtonRow>
                </Col>
              </Row>
            </Container>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getAcoes();
    getEmails();
  }, []);

  useEffect(() => {
    if (user.user?.id) {
      getLojas();
    }
  }, [user.user?.id]);

  useEffect(() => {
    const regs = formVincularEmail.Vinculos.vinculos.map((vinculo) => {
      return {
        id: vinculo.cod_loja.value.value,
        cod_acao: formVincularEmail.cod_acao.value.value,
        cod_email: vinculo.cod_email.value.value,
        cod_loja: vinculo.cod_loja.value.value,
      };
    });
    setVinculos(regs);
  }, [formVincularEmail.Vinculos.vinculos]);

  useEffect(() => {
    const codAcao = formVincularEmail.cod_acao.value.value;
    if (codAcao && !isUpdate) {
      getVinculos();
    }
  }, [formVincularEmail.cod_acao.value.value]);

  async function getAcoes() {
    try {
      const res = await api.get('/acaoemail');
      const { success, data, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      const regs = data.map((acao: IAcaoEmail) => {
        return {
          value: acao.cod_acao,
          label: acao.des_acao,
        };
      });
      const regsOrder = regs.sort((a: any, b: any) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
      setAcoes(regsOrder);
    } catch (e: any) {
      toast.warning(e.message);
    }
  }

  async function getEmails() {
    const res = await api.get('/email');
    const { success, data, message } = res.data;
    const regs = data.map((email: IEmail) => {
      return {
        value: email.cod_email,
        label: `${email.des_nome} <${email.des_email_entrada}>`,
      };
    });

    const regsOrder = regs.sort((a: any, b: any) => {
      if (
        a.label.split(' ')[0].toUpperCase() >
        b.label.split(' ')[0].toUpperCase()
      ) {
        return 1;
      }
      if (
        a.label.split(' ')[0].toUpperCase() <
        b.label.split(' ')[0].toUpperCase()
      ) {
        return -1;
      }
      return 0;
    });
    setEmails(regsOrder);
  }

  async function getLojas() {
    const res = await api.get(`/lojas/usuario`);
    const { data } = res.data;

    const formataLoja = (loja: ILoja) => {
      return {
        label:
          loja.cod_loja < 10
            ? `0${loja.cod_loja} - ${loja.des_loja} (${loja.des_cidade})`
            : `${loja.cod_loja} - ${loja.des_loja} (${loja.des_cidade})`,
        value: loja.cod_loja,
      };
    };

    const regs = data.map((loja: ILoja) => {
      return formataLoja(loja);
    });

    const regsAtivos = data.flatMap((reg: any) => {
      if (reg.flg_inativa !== true) {
        return [formataLoja(reg)];
      }
      return [];
    });

    setLojas(regs);
    setLojasAtivas(regsAtivos);
  }

  async function getVinculos() {
    const codAcao = formVincularEmail.cod_acao.value.value;
    const res = await api.get(`/vincularemail/vinculos/${codAcao}`);
    const { success, data, message } = res.data;
    if (data.length > 0) {
      const regs = data.map((item: IVinculo) => {
        return {
          cod_email: {
            value: { label: '', value: item.cod_email },
            isInvalid: false,
            isRequired: false,
            invalidLabel: undefined,
          },
          cod_loja: {
            value: { label: '', value: item.cod_loja },
            isInvalid: false,
            isRequired: false,
            invalidLabel: undefined,
          },
        };
      });
      setFormVincularEmail({
        ...formVincularEmail,
        Vinculos: {
          ...formVincularEmail.Vinculos,
          vinculos: regs,
        },
      });
    } else {
      setFormVincularEmail({
        ...formVincularEmail,
        Vinculos: {
          ...formVincularEmail.Vinculos,
          vinculos: [],
        },
      });
    }
  }

  function validaFormVincularEmail(inputs: IFormVincularEmail): boolean {
    for (let i = 0; i < Object.keys(inputs).length; i++) {
      if (
        formVincularEmail[Object.keys(inputs)[i] as keyof IFormVincularEmail]
          .isRequired &&
        formVincularEmail[Object.keys(inputs)[i] as keyof IFormVincularEmail]
          .isInvalid
      ) {
        return true;
      }
    }
    return false;
  }

  async function handleSubmit() {
    if (validaFormVincularEmail(formVincularEmail)) {
      setInitInicializado(true);
      toast.warning('Há informações pendentes.');
      return;
    }

    setInitInicializado(false);
    const regsVinculos = formVincularEmail.Vinculos.vinculos.map((item) => {
      return {
        cod_loja: item.cod_loja.value.value,
        cod_email: item.cod_email.value.value,
      };
    });
    const upsert = {
      cod_acao: formVincularEmail.cod_acao.value.value,
      vinculos: regsVinculos,
    };
    setLoader(true);

    if (!isUpdate) {
      const res = await api.post('/vincularemail', upsert);
      const { success, message } = res.data;
      onClearForm();
      setLoader(false);
      return toast.success(message);
    }

    const cod_acao = formVincularEmail.cod_acao.value.value;
    const res = await api.put(`/vincularemail/${cod_acao}`, upsert);
    const { success, message } = res.data;
    if (success) {
      toast.success(message);
    } else {
      toast.warning(message);
    }
    setLoader(false);
    onClearForm();
  }

  async function onCancel() {
    if (isChanged) {
      MySwal.fire({
        title: `Vincular e-mail`,
        text: 'Esta ação cancelará todas as alterações não salvas. Deseja continuar?',
        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsUpdate(false);
          setChanged(false);
          onClearForm();
        }
      });
    }
  }

  function onClearFormVincular() {
    setFormVincular({
      cod_email: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_loja: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
    });
  }

  function onClearForm() {
    setFormVincularEmail({
      cod_acao: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      Vinculos: {
        vinculos: [],
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
    });

    onClearFormVincular();
    setIsDisabledField(false);
    setInitInicializado(false);
    setChanged(false);
    setIsUpdate(false);
    setDisabled(true);
  }

  function validaFormVincular(inputs: IFormVincular): boolean {
    for (let i = 0; i < Object.keys(inputs).length; i++) {
      if (
        formVincular[Object.keys(inputs)[i] as keyof IFormVincular]
          .isRequired &&
        formVincular[Object.keys(inputs)[i] as keyof IFormVincular].isInvalid
      ) {
        return true;
      }
    }
    return false;
  }

  function onAddVinculo() {
    if (
      validaFormVincular(formVincular) ||
      validaFormVincularEmail(formVincularEmail)
    ) {
      setInitInicializado(true);
      toast.warning('Há informações pendentes.');
      return;
    }
    setChanged(true);
    setInitInicializado(false);

    const hasExists = formVincularEmail.Vinculos.vinculos.find((item) => {
      return (
        Number(item.cod_loja.value.value) ===
        Number(formVincular.cod_loja.value.value)
      );
    });

    if (hasExists) {
      if (!isUpdate) {
        MySwal.fire({
          title: `Vincular e-mail`,
          text: 'Esta ação substituirá a configuração atual. Deseja continuar?',
          showCancelButton: true,
          confirmButtonColor: '#07289e',
          cancelButtonColor: '#ff7b7b',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const newVinculos = formVincularEmail.Vinculos.vinculos.map(
              (item) => {
                if (
                  Number(item.cod_loja.value.value) ===
                  Number(formVincular.cod_loja.value.value)
                ) {
                  item = formVincular;
                }
                return item;
              },
            );
            setFormVincularEmail({
              ...formVincularEmail,
              Vinculos: {
                ...formVincularEmail.Vinculos,
                vinculos: newVinculos,
              },
            });
            onClearFormVincular();
          }
        });
      } else {
        const newVinculos = formVincularEmail.Vinculos.vinculos.map((item) => {
          if (
            Number(item.cod_loja.value.value) ===
            Number(formVincular.cod_loja.value.value)
          ) {
            item = formVincular;
          }
          return item;
        });
        setFormVincularEmail({
          ...formVincularEmail,
          Vinculos: {
            ...formVincularEmail.Vinculos,
            vinculos: newVinculos,
          },
        });
        onClearFormVincular();
      }
    } else {
      setFormVincularEmail({
        ...formVincularEmail,
        Vinculos: {
          ...formVincularEmail.Vinculos,
          vinculos: [...formVincularEmail.Vinculos.vinculos, formVincular],
        },
      });
    }
    onClearFormVincular();
    setIsDisabledField(false);
    setDisabled(false);
  }

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <InputSelect
              options={acoes}
              label="Ação"
              noOptionsMessage="Nenhuma ação encontrada"
              value={
                acoes.find((item: ISelect) => {
                  return item.value === formVincularEmail.cod_acao.value.value;
                }) ?? {
                  value: undefined,
                  label: 'Selecione',
                }
              }
              isRequired={formVincularEmail.cod_acao.isRequired}
              setInvalid={formVincularEmail.cod_acao.isInvalid}
              isDisabled={isDisableField}
              iniInicializado={initInicializado}
              onChange={(value: any, isInvalid = true) => {
                acoes.sort();
                setFormVincularEmail({
                  ...formVincularEmail,
                  cod_acao: {
                    ...formVincularEmail.cod_acao,
                    value,
                    isInvalid: value.value === undefined,
                  },
                });
              }}
            />
          </div>
        </div>

        <div className="">
          <Separator labelText="Lojas" />
        </div>

        <div className="row d-flex align-items-center">
          <div className="flex col-sm-12 col-md-5  selectContainer">
            <InputSelect
              options={lojasAtivas}
              label="Loja"
              noOptionsMessage="Nenhum registro encontrado"
              isDisabled={isDisableField}
              value={
                lojas.find((item: ISelect) => {
                  return item.value === formVincular.cod_loja.value.value;
                }) ?? {
                  value: undefined,
                  label: 'Selecione',
                }
              }
              setInvalid={formVincular.cod_loja.isInvalid}
              isRequired={formVincular.cod_loja.isRequired}
              iniInicializado={initInicializado}
              onChange={(value: any, isInvalid = true) => {
                setFormVincular({
                  ...formVincular,
                  cod_loja: {
                    ...formVincular.cod_loja,
                    value,
                    isInvalid: value.value === undefined,
                  },
                });
              }}
            />
          </div>

          <div className="col-sm-12 col-md-5 emailContainer">
            <InputSelect
              options={emails}
              label="E-mail"
              noOptionsMessage="Nenhum registro encontrado"
              value={
                emails.find((item: ISelect) => {
                  return item.value === formVincular.cod_email.value.value;
                }) ?? {
                  value: undefined,
                  label: 'Selecione',
                }
              }
              setInvalid={formVincular.cod_email.isInvalid}
              isRequired={formVincular.cod_email.isRequired}
              iniInicializado={initInicializado}
              onChange={(value: any, isInvalid = true) => {
                setFormVincular({
                  ...formVincular,
                  cod_email: {
                    ...formVincular.cod_email,
                    value,
                    isInvalid: value.value === undefined,
                  },
                });
              }}
            />
          </div>

          <div className="col-sm-2 col-md-2 d-flex justify-content-start containerButton">
            <ConfirmButton
              style={{
                marginTop: '30px',
                minWidth: '146px',
              }}
              onClick={() => onAddVinculo()}
            >
              Confirmar
            </ConfirmButton>
          </div>
        </div>
        <div className="row">
          <div
            className="col-sm-12 col-md-12"
            style={{
              height: 280,
              width: '100%',
              marginTop: '16px',
            }}
          >
            <DataGrid
              rows={vinculos}
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
              disableSelectionOnClick
              columns={unidadeColumns}
              pageSize={5}
              localeText={{
                noRowsLabel: '',
              }}
            />
          </div>
          <Separator />
          <FooterDefault codTela={52}>
            <>
              <ButtonForm
                className={isDisabled ? 'gravar disabled' : 'gravar'}
                disabled={isDisabled}
                onClick={handleSubmit}
                type="button"
              >
                Gravar
              </ButtonForm>
              <ButtonForm className="cancelar" onClick={onCancel} type="button">
                Cancelar
              </ButtonForm>
            </>
          </FooterDefault>
        </div>
      </Container>
    </>
  );
};

export default VincularEmail;
