import styled from 'styled-components';
import { TooltipPrimitive } from '@atlaskit/tooltip';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .btn-actions,
  .btn-actions:focus {
    border: none;
    outline: none;
    background-color: transparent;
  }

  .field-error {
    border-radius: 4px;
    border-bottom: 2px solid #cf1919;
    transition: 0.5s;
  }

  @media (max-width: 1199px) {
    .selectContainer {
      width: 40% !important;
    }
    .emailContainer {
      width: 40% !important;
    }
  }

  @media (max-width: 992px) {
    .selectContainer {
      width: 100% !important;
    }
    .emailContainer {
      width: 72% !important;
    }
  }
  @media (max-width: 767px) {
    .selectContainer {
      width: 100% !important;
    }
    .emailContainer {
      width: 100% !important;
    }
    .containerButton {
      width: 100% !important;

      .confirm {
        width: 100% !important;
      }
    }
  }

  .MuiDataGrid-columnHeader--moving {
    background: #8850bf !important;
  }
`;

export const CutomizedTooltip = styled(TooltipPrimitive)`
  color: #ffff;
  padding: 5px 10px;
  background-color: #57069e;
  border: none;
  border-radius: 0.5rem;
`;

export const StyledForm = styled.form`
  /* margin-top: 20px; */
  padding: 20px;
  border-radius: 5px;
  /* display: flex; */
  .inlineField {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;

  .iconStyle {
    font-size: 12px;
    margin-top: -3px;
    margin-right: 2px;
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  outline: none;

  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;

export const ButtonForm = styled.button`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 160px;
  background-color: #28a745;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 16px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  animation: all 0.25s ease-in;

  &.duplicate {
    background: #007bff;
    width: 300px;
  }
  &.return-search {
    background: #ffc107;
    width: 190px;
  }

  &.limpar {
    background: #ffc107;
  }

  &.cancelar {
    background: #dc3545;
  }

  &.novo {
    background: #007bff;
  }

  &.delete {
    background: #dc3545;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 992px) {
    width: 100% !important;
    padding: 3px 10px !important;
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
`;
